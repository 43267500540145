import Vue from 'vue'
import vSelect from 'vue-select'
import axios from "axios";
import Vuetable from 'vuetable-2'
import $ from 'jquery'
Vue.component('v-select', vSelect)
import Datepicker from 'vuejs-datepicker';
import moment from "moment";


import ModalTiersAuxiliaires from '../../components/Tiers/ModalTiersAuxiliaires.vue';


export default {
    components: {
        Vuetable,
        vSelect,
        axios,
        Datepicker,
        ModalTiersAuxiliaires
    },
    data: function() {
        return {
            pageTitle: "Saisie des écritures",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            journauxmodaltitle: "Choisir le code journal",
            listeCompte: [],
            listeCompteAux: [],
            journauxDeSaisie: [],
            fields: [],
            items: [],
            montant_total_debit: 0,
            montant_total_credit: 0,
            btnValider: 'false',
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'fas fa-chevron-up',
                    descendingIcon: 'fas fa-chevron-down',
                    handleIcon: 'fas fa-spinner',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            journaux: {
                journal_id: "",
                code_journaux: "",
                num_compte_journaux: "",
                numerotation_continue: ""
            },
            generalAdd: {
                date: moment().format('DD/MM/YYYY'), 
                compte: "", 
                libelle: '', 
                description: '', 
                compte_aux: '', 
                compte_aux_libelle: '', 
                montant_debit: '', 
                montant_credit: '0', 
                compte_id: '', 
                compte_aux_id: ''
            },
            idecriture: "",
            numero_ecriture: "",
            current_numero_ecriture: "",
            liste_auxiliaire: ""
        }
    },
    methods: {
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
        },
        openModalJournal(){
            this.initListJournauxDeSaisie();
            this.$bvModal.show("journauxmodal");
        },
        closeModalJournal(){
            this.$bvModal.hide("journauxmodal");
            if(this.journaux.num_compte_journaux != "") {
                this.initListCompte(this.journaux.num_compte_journaux);
                $(".compteGeneralContent .vs__search").focus();
            }
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        addRowDebit(compte = "") {
            var currentDateWithFormat = moment().format('DD/MM/YYYY');
            this.items.push(
                {date: currentDateWithFormat, compte: compte, libelle: '', description: '', compte_aux: '', compte_aux_libelle: '', montant_debit: '0', montant_credit: '', compte_id: '', type: 'debit' }
            );
            this.liste_auxiliaire = "";
        },
        addRowCredit(compte = "") {
            var currentDateWithFormat = moment().format('DD/MM/YYYY');
            this.items.push(
                {date: currentDateWithFormat, compte: compte, libelle: '', description: '', compte_aux: '', compte_aux_libelle: '', montant_debit: '', montant_credit: '0', compte_id: '', type: 'credit' }
            );
            this.liste_auxiliaire = "";
        },
        deleteRow(index) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            this.items.splice(index, 1)
        },
        initListCompte(filtre) {
            //console.log(rowData);
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //this.items[rowData.rowIndex].debit = filtre;
                //$(".vs__search").trigger("blur");
                //$(".vs__search").focus();
            });
            
                this.initListCompteAux(filtre.replace(/^0+|0+$/g, ""));
        },
        initListCompteAux(filtre) {
            axios.get(this.base_url+"/compteauxiliaires/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompteAux = response.data.data;
                console.log(this.listeCompteAux);
            });
        },
        initListJournauxDeSaisie() {
            axios.get(this.base_url+"/journauxdesaisie/getall").then(response => {
                this.journauxDeSaisie = response.data;
            });
        },
        listCompteGeneralOnChange(item) {
            const result = this.listeCompte.find( ({ id }) => id === item );
            this.generalAdd.compte_id = result.id;
            this.generalAdd.compte = result.num_compte;
            this.generalAdd.description = result.intitule;

            this.initListCompteAux(result.num_compte.replace(/^0+|0+$/g, ""));
            // $(".compteAux .vs__search").trigger("blur");
            // this.$refs.select.blur();

            // $(".compteAux .vs__search").focus();

            // $('.libelleGeneral').focus();

        },
        listCompteGeneralAuxOnChange(item) {
            const result = this.listeCompteAux.find( ({ id }) => id === item );
            
            this.generalAdd.compte_aux_id = result.id;
            this.generalAdd.compte_aux = result.num_compte;
            this.generalAdd.compte_aux_libelle = result.libelle;
            // $('.libelleGeneral').focus();

        },
        listCompteDebitOnChange(item, index) {
            // console.log(item);
            // console.log(index);

            const result = this.listeCompte.find( ({ id }) => id === item );
            // console.log(result);
            
            this.items[index].compte_id = result.id;
            this.items[index].compte = result.num_compte;
            this.items[index].description = result.intitule;

            this.initListCompteAux(result.num_compte);
            //this.items[index].description = result.intitule;

        },
        listCompteAuxOnChange(item, index) {
            // console.log(item);
            // console.log(index);

            const result = this.listeCompteAux.find( ({ id }) => id === item );
            // console.log(result);
            
            this.items[index].compte_aux_id = result.id;
            this.items[index].compte_aux = result.num_compte;
            this.items[index].compte_aux_libelle = result.libelle;

        },
        montantDebitOnChange(index) {
            if(Number(this.items[index].montant_debit) > 0)
                this.items[index].montant_credit = 0;
            // this.items[index].montant_debit = item;
            console.log("----------------------");
            console.log(this.items[index].montant_debit);
            // console.log(this.items[index].montant_debit);

        },
        montantCreditOnChange(index) {
            if(Number(this.items[index].montant_credit) > 0)
                this.items[index].montant_debit = 0;
            // this.items[index].montant_credit = item;

        },
        libelleOnChange(item, index) {
            // console.log(item);
            // console.log(index);

            this.items[index].libelle = item;

            // console.log(this.items[index].libelle);

        },
        initCodeJournal(item,) {
            // console.log(item);
            this.journaux.journal_id = '';
            if(item != null) {
                const result = this.journauxDeSaisie.find( ({ id }) => id === item );
                this.journaux.journal_id = item;
                this.journaux.code_journaux = result.libelle;
                this.journaux.num_compte_journaux = result.num_compte;
                this.journaux.numerotation_continue = result.numerotation_continue;

            }

        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/brouillard/action?codejournaux="+that.journaux.code_journaux+"&journal_id="+that.journaux.journal_id, $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log(response.data.message);
                            that.items = [];
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                            // that.journaux.journal_id = "";
                            // that.journaux.code_journaux = "";
                            // that.openModalJournal();
                            that.getLastNumero();
                            // that.nouveauEcriture();
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right',
                                duration: 0,
                                className: ['text-white']
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            

            
        },
        addrowAuto() {

            // var result = this.items.find( ({ compte }) => compte === this.journaux.num_compte_journaux );
            // var compte_ = "";
            
            // var add_row_state = true;
            // var i = 0;
            // for(i=0; i < this.items.length; i++) {
            //     if(
            //         (this.items[i].montant_debit =="" || this.items[i].montant_debit =="0")
            //         && (this.items[i].montant_credit =="" || this.items[i].montant_credit =="0")
            //         ) {
            //             add_row_state = false;
            //             break;
            //         }
            // }

            // if(add_row_state == true) {
            //     if(this.montant_total_debit > this.montant_total_credit) {
            //         this.addRowCredit(compte_);
            //         console.log("this.items.length: "+this.items.length);
            //         // this.items[this.items.length-1].montant_credit = 
                    
            //         if(result != "undefined") {
            //             this.initListCompte(this.journaux.num_compte_journaux);
            //             this.items[this.items.length-1].compte = this.journaux.num_compte_journaux;
            //         }
            //     }
            //     else if(this.montant_total_debit < this.montant_total_credit) {
            //         this.addRowDebit(compte_);
                    
            //         if(result != "undefined") {
            //             this.initListCompte(this.journaux.num_compte_journaux);
            //             this.items[this.items.length-1].compte = this.journaux.num_compte_journaux;
            //         }
            //     }
            // }
            

        },
        AddNewLine(event, element) {
            console.log(event);
            // var currentDateWithFormat = moment().format('DD/MM/YYYY');
            // alert("ok");
            if(this.generalAdd.montant_debit <= 0 && this.generalAdd.montant_credit <= 0)
            return;

            if(event.key == "Enter") {
                
                this.items.push (
                    {
                        date: this.generalAdd.date, 
                        compte: this.generalAdd.compte, 
                        compte_id: this.generalAdd.compte_id, 
                        libelle: this.generalAdd.libelle, 
                        description: this.generalAdd.description, 
                        compte_aux: this.generalAdd.compte_aux, 
                        compte_aux_libelle: this.generalAdd.compte_aux_libelle, 
                        compte_aux_id: this.generalAdd.compte_aux_id, 
                        montant_debit: this.generalAdd.montant_debit, 
                        montant_credit: this.generalAdd.montant_credit,
                        type: this.generalAdd.type,
                    }
                );
                
                this.generalAdd.montant_debit = 0;
                this.generalAdd.montant_credit = 0;
                this.generalAdd.compte_aux = "";
                this.generalAdd.compte_aux_libelle = "";
                this.generalAdd.compte_aux_id = "";
                this.initListCompteAux('');
                
                // this.calculSommeMontant();
                // if(this.montant_total_debit > this.montant_total_credit) {
                //     this.generalAdd.montant_credit = Number(this.montant_total_debit) - Number(this.montant_total_credit);
                // } else {
                //     this.generalAdd.montant_debit = Number(this.montant_total_credit) - Number(this.montant_total_debit);
                // }

                if(element == "montant_debit") {
                    this.$refs.montant_debit.$el.focus();
                } if(element == "montant_credit") {
                    this.$refs.montant_credit.$el.focus();
                }
                $(".compteGeneralContent .vs__search").focus();
                // this.calculSommeMontant();
                
            }
            
            // alert("okkkkk");
        },
        initEcritureEdit(brouillard_groupe_id) {

            axios.get(this.base_url+"/brouillard/getbygroupeid/"+brouillard_groupe_id).then(response => {
                console.log("Edition d'ecriture!!!!");
                console.log(response.data);

                this.journaux.num_compte_journaux = response.data.groupe[0].num_compte;
                this.journaux.journal_id = response.data.groupe[0].journal_id;
                this.journaux.code_journaux = response.data.groupe[0].code_journaux;
                this.pageTitle = "Edition écriture n° " + response.data.groupe[0].num_ecriture;
                this.$emit('change-page', this.pageTitle);

                this.numero_ecriture =  response.data.groupe[0].num_ecriture;
                this.current_numero_ecriture =  response.data.groupe[0].num_ecriture;

                var ecriture_detail = response.data.detail;
                let i_count = 0;
                for(i_count = 0; i_count <= ecriture_detail.length; i_count++) {
                    var currentDateWithFormat = moment(ecriture_detail[i_count].date).format('DD/MM/YYYY');
                    this.items.push(
                        {
                            date: currentDateWithFormat, 
                            compte: ecriture_detail[i_count].compte, 
                            libelle: ecriture_detail[i_count].libelle, 
                            description: ecriture_detail[i_count].description, 
                            compte_aux: ecriture_detail[i_count].compte_aux, 
                            compte_aux_id: ecriture_detail[i_count].compte_aux_id, 
                            compte_aux_libelle:  ecriture_detail[i_count].compte_aux_libelle, 
                            montant_debit: ecriture_detail[i_count].montant_debit, 
                            montant_credit: ecriture_detail[i_count].montant_credit, 
                            compte_id: ecriture_detail[i_count].plan_comptable_id, 
                            type: ecriture_detail[i_count].montant_debit == "0" ? 'credit' : 'debit'
                        }
                    );
                }

                // var currentDateWithFormat = moment().format('DD/MM/YYYY');
                // this.items.push(
                //     {date: currentDateWithFormat, compte: compte, libelle: '', description: '', compte_aux: '', compte_aux_libelle: '', montant_debit: '', montant_credit: '0', compte_id: '', type: 'credit' }
                // );
            });
        },
        nouveauEcriture() {
            this.items = [];
            this.idecriture = "";
            this.journaux.num_compte_journaux = "";
            this.journaux.journal_id = "";
            this.journaux.code_journaux = "";
            this.pageTitle = "Saisie des écritures";
            this.$emit('change-page', this.pageTitle);
            this.getLastNumero();
        },
        nouveauTiers() {
            this.$refs.modalAuxComponent.addRow();
            // console.log(this.$refs.modalAuxComponent);
        },
        getLastNumero() {
            // alert(this.journaux.journal_id);
            if((this.$route.name != 'EcrituresEdit')) {
                axios.get(this.base_url+"/brouillard/getlastid/"+this.journaux.journal_id+'?date='+this.generalAdd.date+'&numerotation_continue='+this.journaux.numerotation_continue).then(response => {
                    this.numero_ecriture = response.data;
                    this.verifierNumEcriture();
                });
            }
            else {
                this.numero_ecriture = this.current_numero_ecriture;
            }
        },
        verifierNumEcriture() {
            axios.get(this.base_url+"/brouillard/checknumecriture/"+this.numero_ecriture+"?id_edit="+this.idecriture+"&journal_id="+this.journaux.journal_id+"&code_journaux="+this.journaux.code_journaux+"&date_ecriture="+this.generalAdd.date).then(response => {
                console.log(response.data);
                if(response.data.status == "false") {
                    $('.num_ecriture').removeClass("bg-yellow ");
                    $('.num_ecriture').addClass("text-white bg-danger");
                } else {
                    this.numero_ecriture = response.data.num_ecriture;
                    $('.num_ecriture').addClass("bg-yellow ");
                    $('.num_ecriture').removeClass("text-white bg-danger");
                }
            });
        }

    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        // this.addRowDebit();
        // this.addRowCredit();

        var that = this;
        this.$watch("items", function (after, before) {
            
            console.log(before);
            console.log(after);
            that.montant_total_debit = 0;
            that.montant_total_credit = 0;
            for(var i_count = 0; i_count < that.items.length; i_count++) {
                that.montant_total_debit += Number(that.items[i_count].montant_debit);
                that.montant_total_credit += Number(that.items[i_count].montant_credit);
            }

            if(that.montant_total_debit > that.montant_total_credit) {
                that.generalAdd.montant_credit = Number(that.montant_total_debit) - Number(that.montant_total_credit);
            } else {
                that.generalAdd.montant_debit = Number(that.montant_total_credit) - Number(that.montant_total_debit);
            }

        }, {deep: true})

        // alert(this.$route.name);
        if (this.$route.name != 'EcrituresEdit') {
            this.getLastNumero();
        }
        
    },
    mounted: function() {

        console.log(this.$route);
        if (this.$route.name == 'EcrituresEdit') {
            this.idecriture = this.$route.params.idecriture;
            this.initEcritureEdit(this.$route.params.idecriture);
        }
        else {
            this.idecriture = "";
            this.openModalJournal();
        }
        
    },
    watch: {
        
        "generalAdd.montant_credit": function() {
            // console.log(this.generalAdd.montant_credit);
            this.generalAdd.montant_debit = 0;
        },
        "generalAdd.montant_debit": function() {
            // console.log(this.generalAdd.montant_credit);
            this.generalAdd.montant_credit = 0;
        },
        "$route.params.idecriture": function() {
            this.initEcritureEdit(this.$route.params.idecriture);

        },
        "journaux.num_compte_journaux": function() {
            if((this.$route.name != 'EcrituresEdit'))
                this.getLastNumero();
        },
        // "numero_ecriture": function() {
        //     this.verifierNumEcriture();
        // }
        
    },
}